class Helper {
    static allTrue(array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] !== true) {
                return false;
            }
        }
        return true;
    }

    sendXHR(data, url, method = "POST") {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log(xhr.responseText);
            }
        };
        xhr.open(method, url);
        xhr.send(data);
    }

    areKeysMatching(a, b, ignore = null) {
        if (ignore) {
            a = a.filter(key => !ignore.includes(key));
            b = b.filter(key => !ignore.includes(key));
        }
        const hasAllKeys = a.every(key => b.includes(key));
        const hasAllKeysAlt = b.every(key => a.includes(key));
        const sameLength = a.length === b.length;
        return hasAllKeys && hasAllKeysAlt && sameLength;
    }
}

export {Helper};

if (!window.nrg) window.nrg = {};
window.nrg.helper = new Helper();