import {Form} from './Form'

export class Forminator {
    constructor() {
        this.forms = [];
        document.querySelectorAll("form:not(.ignore)").forEach(item => {
            this.forms.push(new Form(item))
            item.setAttribute('forminator', '');
        })
    }
}