import {Helper} from "../../modules/Helper.js";

export class Form {
    constructor(element) {
        this.element = element;
        this.files = this.element.querySelectorAll('input[type="file"]');
        this.setListeners();
    }

    setListeners() {
        this.element.addEventListener('submit', e => {
            e.preventDefault();
            this.toggleSubmit();
            this.logSubmission();
            this.sendData(new FormData(this.element))
        });
    }

    toggleSubmit(disabled = true) {
        if (disabled) {
            if (!this.element.hasAttribute('unload-listen')) {
                this.element.setAttribute('unload-listen', '');
                window.addEventListener('beforeunload', this.preventReload);
            }
        } else {
            this.element.removeAttribute('unload-listen');
            window.removeEventListener('beforeunload', this.preventReload);
        }

        this.element.querySelectorAll('[type="submit"]').forEach(item => {
            if (disabled) item.setAttribute('disabled', '')
            else item.removeAttribute('disabled');
        });
    }

    sendData(data) {
        let FORM = this;
        let url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
        if (this.element.hasAttribute('action') && this.element.getAttribute('action') !== "") {
            if (this.element.getAttribute('action').startsWith('http')) {
                url = this.element.getAttribute('action');
            } else {
                url += this.element.getAttribute('action');
            }
        } else {
            url += "/form/store";
        }

        let xhr = new XMLHttpRequest();

        if (!this.checkFiles()) {
            this.createPopup('Invalid files', 'error');
            return;
        }

        if (this.hasFiles(this.element)) {
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    console.log(`Upload Progress: ${percentComplete.toFixed(2)}%`, event);
                }
            };
        }

        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE) {
                let response = {success: false, reason: 'An error occurred'};
                try {
                    response = JSON.parse(this.responseText);
                } catch (e) {
                    FORM.createPopup(e, 'error')
                }
                switch (this.status) {
                    case 200:
                        if (response.success) {
                            FORM.element.removeAttribute('unload-listen');
                            window.removeEventListener('beforeunload', FORM.preventReload);
                            if (response.redirect) {
                                window.location.replace(response.redirect);
                                return;
                            }
                            FORM.createPopup("Success", 'success')
                            if (response.remove) {
                                FORM.element.closest(response.remove)?.remove();
                            }
                            if (response.refresh)
                                window.location.reload();

                            FORM.reset();
                        } else {
                            FORM.createPopup(response.reason, 'error')
                        }
                        FORM.toggleSubmit(false);
                        break;
                    default:
                        if (response.success === false) {
                            FORM.createPopup(response.reason, 'error')
                        }
                        FORM.toggleSubmit(false);
                }
            }
        };
        xhr.open("POST", url);
        xhr.send(data);
    }

    reset() {
        //this.element.reset();
        let progressBar = this.element.querySelector('#progressbar');
        if (progressBar) progressBar.classList.remove('active');
        if (progressBar) progressBar.classList.remove('completed');
        this.toggleSubmit(false);
    }

    createPopup(content, type) {
        Toastify({
            text: content,
            duration: 3000,
            close: true,
            gravity: "bottom", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: colors[type],
        }).showToast();
    }

    handleFiles() {
        console.error('Create handler');
    }

    checkFiles() {
        let valid = [];
        let inputs = Array.from(this.element.querySelectorAll('input[type="file"]'));
        inputs.some(file => {
            if (file.hasAttribute('multiple')) {
                let max = file.getAttribute('multiple');
                console.log(max);
                if (max > 0 && file.files.length > max) {
                    this.createPopup(`You can only upload ${max} files`, 'error');
                    valid.push(false)
                    return;
                }
                valid.push(true)
            } else {
                valid.push(file.files.length <= 1);
            }
        });
        console.log(valid);
        console.log(valid.length, inputs.length, Helper.allTrue(valid));
        return valid.length === inputs.length && Helper.allTrue(valid);
    }

    logSubmission() {
        return;
        //@TODO handle logging of form submission
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) console.log("ok");
        };
        xhr.open("POST", "/form/submission");
        xhr.send(new FormData(this.element));
    }

    preventReload(e) {
        e.preventDefault();
        return "Form is submitting, please wait!";
    }

    hasFiles(form) {
        let hasFiles = false;
        console.log(form);
        Array.from(form.querySelectorAll('input[type="file"]')).some(file => {
            if (file.files.length > 0) {
                hasFiles = true;
                return true;
            }
        });
        return hasFiles;
    }
}


const colors = {
    'success': {
        backgroundColor: '#12e298',
        color: '#000'
    },
    'error': {
        backgroundColor: '#e21212',
        color: '#fff'
    }
}